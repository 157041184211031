import { useEffect, useState } from 'react'
import './table-email.component.scss'
import { Email, EmailOpen, Star, StarFill, TrashFillSm } from 'constants/icons.constants'
import moment from 'moment'
import EmailDataService from 'data-services/contact/contact-data.service'
import { message, Modal, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { tableSettings } from 'constants/default.constants'
import UserInfoPopup from '../user-info-popup.component'
import EmailDetailModal from '../Detail/email-detail.component'
export default function TableEmail(props){
  const {isImportant = false, isRead = false, isReply = false, tabChange} = props
  const [pageNumber, setPageNumber] = useState(1)
  const [keySearch, setKeySearch] = useState('')
  const [actionRender, setRenderAction] = useState(null)
  const [infoRender, setInfoRender] = useState(null)
  const [email, setEmail] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const {t} = useTranslation()
  const [dataSource, setDataSource] = useState([])
  const getEmails = async ()=>{
    const request = {
      pageNumber: pageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: keySearch,
      isImportant: isImportant,
      isRead: isRead,
      isReply: isReply
    }
    try{
      const response = await EmailDataService.getEmailsAsync(request)
      if(response){
        setDataSource(response?.result)
      }
    }catch(err){
      console.error(err)
    }
  }
  const pageData = {
    updateEmailSuccess: t('email.updateEmailSuccess'),
    updateEmailFail: t('email.updateEmailFail')
  }
  const changeReadStatus = async(id) => {
    try{
      const res = await EmailDataService.changeReadStatus(id)
      if(res){
        message.success(pageData.updateEmailSuccess)
        await getEmails()
      }
    }catch(err){
      console.error(err)
      message.error(pageData.updateEmailFail)
    }
  }
  const changeImportantStatus = async(id) => {
    try{
      const res = await EmailDataService.changeImportantStatus(id)
      if(res){
        message.success(pageData.updateEmailSuccess)
        await getEmails()
      }
    }catch(ex){
      console.error(ex)
      message.error(pageData.updateEmailFail)
    }

  }
  const onReadBtnClick = (event, id) => {
    event.stopPropagation(); // Prevents other listeners from being triggered
    event.preventDefault(); // Cancels the default action
    changeReadStatus(id)
  }
  const renderAction = (record)=>{
    return (
      <div className='d-flex justify-content-around w-100'>
        <div onClick={(event) => onReadBtnClick(event, record?.id)}>
          {record?.isRead ? <Email/> : <EmailOpen/>}
        </div>
        <div>
          <TrashFillSm onClick={(event) => onDeleteEmalConfirm(event, record)}/>
        </div>
      </div>
    )
  }

  useEffect(()=>{
    getEmails()
  },[tabChange])
  const tableSetting = {
    pageSize: 20,
    columns: [
      {
        key: 'isImportant',
        dataIndex: 'isImportant',
        width: '5%',
        render: (value, record)=> <div onClick={(event)=>{
          event.stopPropagation()
          changeImportantStatus(record?.id)}}>
          {!record?.isImportant ? <Star/> : <StarFill/>}</div>
      },
      {
        key: 'userName',
        width: '25%',
        dataIndex: 'userName',
        align: 'left',
        render: (value, record, index) => {
          return(
            <div className='info-popup-container' onMouseEnter={()=>setInfoRender(index)} onMouseLeave={()=>setInfoRender(null)}>
              {value}
              <div className={`${infoRender === index ? 'user-info-container' : 'd-none'}`}>
                <UserInfoPopup name={value} email={record?.userEmail}/>
              </div>
            </div>
          )
        }

      },
      {
        key: 'message',
        width: '60%',
        dataIndex: 'message',
        ellipsis: true,
        align: 'left'

      },
      {
        key: 'createdTime',
        width: '10%',
        dataIndex: 'createdTime',
        render: (value, record, index) => {
          return(
            <div className='w-100'>
              {
                actionRender === index ? renderAction(record) :
                  moment(value).isSame(moment(), 'day') ? moment(value).format('HH:mm') : moment(value).format('DD-MM')
              }
            </div>
          )
        }
      }
    ]
  }
  const onSelectRow = (record) => {
    setEmail(record)
    setOpenModal(true)
  }
  const onCloseModal = async(email) => {
    if(!email?.isRead) changeReadStatus(email?.id)
    setOpenModal(false)
    await getEmails()
  }
  const onDeleteEmail = async id => {
    try{
      const res = EmailDataService.deleteEmailById(id)
      if(res){
        message.success(pageData.updateEmailSuccess)
        setDeleteConfirm(false)
        setEmail(null)
        setOpenModal(false)
        await getEmails()
      }
    }catch(err){
      console.error(err)
      message.error(pageData.updateEmailFail)
    }
  }
  const onDeleteEmalConfirm = (event, email) => {
    event.stopPropagation()
    setEmail(email)
    setDeleteConfirm(true)
  }
  return(
    <>
      <Table
        onRow={(record, index)=>{
          return {
            onMouseEnter: () => setRenderAction(index),
            onMouseLeave: () => setRenderAction(null),
            onClick: () => onSelectRow(record)
          }
        }}
        dataSource={dataSource}
        columns={tableSetting.columns}
        rowClassName={(record, index) => `${record?.isRead ? 'is-read' : 'unread'}`}
        pagination={{
          current: pageNumber,
          showTotal: () => t('email.pagination',{
            showingRecords: pageNumber,
            pageSize: tableSettings.pageSize,
            totalRecord: dataSource?.length
          }),
          hideOnSinglePage: true
        }}
      />
      <EmailDetailModal
        isModalOpen={openModal}
        email={email}
        onCancel={()=>onCloseModal(email)}
        onChangeImportantStatus={changeImportantStatus}
        onDeleteEmalConfirm={onDeleteEmalConfirm}
      />
      <Modal className='delete-email-modal'
        open={deleteConfirm}
        onOk={() => onDeleteEmail(email?.id)}
        onCancel={() => setDeleteConfirm(false)}
        okText={t('dialog.confirmDelete')}
        cancelText={t('dialog.confirmCancel')}
        okButtonProps={{danger: true}}
        closable={false}
      >
        {t('email.deleteEmailConfirm',{userName: email?.userName})}
      </Modal>
    </>
  )
}