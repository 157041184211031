import { Button, Image } from 'antd';
import { images } from 'constants/images.constants';
import { useTranslation } from 'react-i18next';

export default function UserInfoPopup(props){
  const {t} = useTranslation();
  const pageData = {
    answerEmail: t('email.answerEmail')
  }
  const {name, email} = props
  return(
    <div>
      <div className='d-flex p-3 justify-content-evenly w-100 user-info'>
        <Image src={images.thumbnailDefault}/>
        <div>
          <p>{name}</p>
          <p>{email}</p>
        </div>
      </div>
      <div className='p-2'>
        <button>{pageData.answerEmail}</button>
      </div>
    </div>
  )
}