const actionTypes = {
  SET_STORE_INFO: 'SET_STORE_INFO',
  SET_AUTH: 'SET_AUTH',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_WORKSPACE: 'SET_WORKSPACE',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  RESET_SESSION: 'RESET_SESSION',
  SET_THUMBNAIL: 'SET_THUMBNAIL',
  SET_FULL_NAME: 'SET_FULL_NAME',
  STORE_LOGO: 'STORE_LOGO',
  SET_SELECTED_SUB_MENU_ID: 'SET_SELECTED_SUB_MENU_ID'
}

export default actionTypes
