import { Card, Checkbox, Col, Row } from 'antd'
import permissionDataService from 'data-services/permission/permission-data.service'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function ShopPermissionInput(props){
  const { permissionIds, setPermissionIds, setIsFormChange } = props
  const [groupPermissions, setGroupPermissions] = useState([])
  const [activeTabKey, setTab] = useState(0)
  const [t] = useTranslation()
  const pageData = {
    permission: {
      title: t('staff.titlePermission'),
      selectGroup: {
        label: t('staff.labelPermission'),
        placeholder: t('staff.placeholderPermission'),
        required: true,
        validateMessage: t('staff.validatePermission')
      },
      allpermission: [
        [t('staff.permissionAdmin')],
        [
          t('staff.permissionViewProduct'),
          t('staff.permissionCreateProduct'),
          t('staff.permissionEditProduct')
        ],
        [
          t('staff.permissionViewProductCategory'),
          t('staff.permissionCreateProductCategory'),
          t('staff.permissionEditProductCategory')
        ],
        [
          t('staff.permissionViewCustomer'),
          t('staff.permissionCreateCustomer'),
          t('staff.permissionEditCustomer')
        ],
        [
          t('staff.permissionViewStaff'),
          t('staff.permissionCreateStaff'),
          t('staff.permissionEditStaff')
        ],
        [
          t('staff.permissionViewOrder'),
          t('staff.permissionCreateOrder'),
          t('staff.permissionEditOrder')
        ],
        [
          t('staff.permissionViewBlog'),
          t('staff.permissionCreateBlog'),
          t('staff.permissionEditBlog')
        ],
        [
          t('staff.permissionStoreWeb')
        ]
      ],
      btnAddGroup: t('staff.btnAddGroupPermission'),
      allGroup: t('staff.allGroupPermission')
    }
  }
  useEffect(() => {
    loadData()
  }, [])
  const loadData = async () => {
    const response = await permissionDataService.getAllPermissionAsync();
    if (response) {
      const permissionGroups = response
      setGroupPermissions(permissionGroups.slice(0, -1))
    }
  }
  const onSelectAllGroups = (event) => {
    setIsFormChange(true)
    if (event.target.checked) {
      setPermissionIds([])
      let allpermission = [];
      groupPermissions.forEach(gp => {
        allpermission = gp.permissions.reduce((acc, curr) => {
          return acc.concat(curr.id)
        }, allpermission)
      })
      setPermissionIds(allpermission)
    }
    else {
      setPermissionIds([])
    }
  }
  const onChangePermission = (e, index) => {
    setIsFormChange(true)
    if (e.target.checked) {
      setPermissionIds(ids => [...ids, groupPermissions[activeTabKey]?.permissions[index]?.id])
    }
    else {
      setPermissionIds(ids => ids.filter(id => id != groupPermissions[activeTabKey]?.permissions[index]?.id))
    }
  }
  return(
    <Card
      className='w-100'
      tabList={groupPermissions?.reduce((acc, cur, index) => {
        return acc.concat({ key: index, tab: cur.name })
      }, [])}
      onTabChange={key => setTab(key)}
    >
      <Row gutter={[8, 16]}>
        {
              groupPermissions[activeTabKey]?.permissions?.map((p, index) => {
                return (
                  <Col key={index} span={24}>
                    <Checkbox
                      checked={activeTabKey == 0 ? groupPermissions.reduce((totalLength, current) => totalLength + current.permissions.length, 0) === permissionIds.length ? true : false : permissionIds.includes(p.id)}
                      onChange={e => activeTabKey == 0 ? onSelectAllGroups(e) : onChangePermission(e, index)}
                    >
                      {pageData.permission.allpermission[activeTabKey][index]}
                    </Checkbox>
                  </Col>
                )
              })
        }
      </Row>
    </Card>
  )
}