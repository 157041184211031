import { buildQueryString } from 'utils/helpers';
import http from 'utils/http-common';
const controller = 'contact'
const getEmailsAsync = (request) => {
  const queryParams= buildQueryString(request)
  return http.get(`/${controller}/get-emails?${queryParams}`)
}
const getEmailById = id => {
  return http.get(`/${controller}/get-email-by-id/${id}`)
}
const changeReadStatus = (id) =>{
  return http.put(`/${controller}/change-read-status/${id}`)
}
const changeImportantStatus = id =>{
  return http.put(`/${controller}/change-important-status/${id}`)
}
const deleteEmailById = id =>{
  return http.delete(`/${controller}/delete-email/${id}`)
}
const sendResponse = request => {
  return http.put(`/${controller}/send-email-response`, request)
}
const EmailDataService = {
  getEmailsAsync,
  getEmailById,
  changeReadStatus,
  changeImportantStatus,
  deleteEmailById,
  sendResponse
}
export default EmailDataService;