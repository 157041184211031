import { useTranslation } from 'react-i18next';
import { Col, Row, Space } from 'antd';
import PageTitle from 'components/page-title';
import TabEmail from './components/Tab/tab-email.component';
import './components/Tab/email-tabs-management.component.scss'

export default function EmailManagement(){
  const {t} = useTranslation()
  const pageData = {
    title: t('email.pageTitle')
  }
  return(
    <Row className='email-management'>
      <Col span={24}>
        <Space className="page-title">
          <PageTitle content={pageData.title} />
        </Space>
      </Col>
      <TabEmail/>
    </Row>

  )
}