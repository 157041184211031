import i18n from 'utils/i18n'
import { Star } from './icons.constants'
import TableEmail from 'pages/email/components/Table/table-email.component'
const { t } = i18n
export const EmailType = (tabChange) => [
  {
    key: 'email.new',
    label: t('email.emailNew'),
    children: <TableEmail tabChange={tabChange}/>
  },
  {
    key: 'email.important',
    label: t('email.emailImportant'),
    icon: <Star/>,
    children: <TableEmail isImportant={true} tabChange={tabChange}/>
  },
  {
    key: 'email.sent',
    label: t('email.emailSent'),
    children: <TableEmail isReply={true} tabChange={tabChange}/>
  }
]