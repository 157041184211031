import { buildQueryString } from 'utils/helpers';
import http from 'utils/http-common';
const controller = 'product'
const getAllProductsAsync = () => {
  return http.get(`/${controller}/get-all-products`);
}
const createProductAsync = data => {
  return http.post(`/${controller}/create-product`, data)
}
const getProductsByFilterAsync = (request) => {
  const queryParams= buildQueryString(request)
  return http.get(`/${controller}/get-products?${queryParams}`)
}
const deleteProductByIdAsync = id => {
  return http.delete(`/${controller}/delete-product-by-id/${id}`);
}
const getProductByIdAsync = id => {
  return http.get(`/${controller}/get-product-by-id?Id=${id}`)
}
const updateProductAsync = data => {
  return http.put(`/${controller}/update-product`, data)
}
const changeStatusAsync = id => {
  return http.put(`/${controller}/change-status/${id}`)
}
const getProductsByCategoryIdAsync = (id, keySearch) => {
  return http.get(`/${controller}/get-products?ProductCategoryId=${id}&KeySearch=${keySearch}&FilterAll=${true}`)
}
const changeFeatureStatus = data => {
  const { id, isActivate } = data
  return http.put(`/${controller}/change-featured-status?Id=${id}&IsActivate=${isActivate}`)
}
const getPreparedDataProductAsync = () => {
  return http.get(`/${controller}/get-prepared-data-product`)
}
const productDataService = {
  getAllProductsAsync,
  createProductAsync,
  getProductsByFilterAsync,
  deleteProductByIdAsync,
  getProductByIdAsync,
  updateProductAsync,
  changeStatusAsync,
  getProductsByCategoryIdAsync,
  changeFeatureStatus,
  getPreparedDataProductAsync
}
export default productDataService;
