import i18n from 'utils/i18n'
import { PermissionKeys } from 'constants/permission-key.constants'
import { Email, EmailMenu } from 'constants/icons.constants'
import EmailManagement from './email-management.page'
const { t } = i18n
const route = [
  {
    key: 'app.email-management',
    position: 5,
    path: '/contacts',
    icon: <EmailMenu/>,
    name: t('email.pageTitle'),
    isMenu: true,
    exact: true,
    auth: true,
    component: EmailManagement,
    permission: PermissionKeys.ADMIN,
    child: []
  }
]
export default route