import { Tabs } from 'antd';
import './tab-email.component.scss'
import { EmailType } from 'constants/email-status.constants';
import EmailDataService from 'data-services/contact/contact-data.service';
import { tableSettings } from 'constants/default.constants';
import { useEffect, useState } from 'react';
export default function TabEmail(props){
  const [tabChange, isTabChange] = useState(true)
  return(
    <Tabs
      className='email-management mt-4'
      defaultActiveKey={EmailType(tabChange)[0].key}
      items={EmailType(tabChange)}
      onTabClick={() => isTabChange(tab => !tab)}
    />
  )
}