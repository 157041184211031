import { CheckOutlined } from '@ant-design/icons'
import { Card, Checkbox, Col, Form, Radio, Row } from 'antd'
import { FnbSelectSingle } from 'components/shop-select-single/shop-select-single'
import { useEffect, useState } from 'react'
import { isJsonString } from 'utils/helpers'
import { getStorage, localStorageKeys, setStorage } from 'utils/localStorage.helpers'
import '../index.scss'
import './filter-product.component.scss'
import { useTranslation } from 'react-i18next'
export default function FilterProduct (props) {
  const [form] = Form.useForm()
  const { categories, onShowFilter, pageSize, keySearch, setDataFilter, productRootCategories, onChangeProductRootCategory } = props
  const defaultValue = ''
  const [resetFilter, setResetFilter] = useState(false)
  const { t } = useTranslation()
  const pageData = {
    filter: {
      category: {
        title: t('table.categoryFilterTitle'),
        all: t('table.categoryFilterAll'),
        placeholder: t('table.categoryFilterPlaceholder')
      },
      status: {
        title: t('table.statusFilterTitle'),
        all: t('table.statusFilterAll'),
        active: t('table.statusFilterActive'),
        inactive: t('table.statusFilterInactive')
      },
      resetAllFilters: t('table.resetAllFilters'),
      productRootCategory: {
        title: t('table.productRootCategoryFilterTitle')
      },
      isNewin: t('table.newinFilter'),
      isDiscount: t('table.discountFilter'),
      isSoldout: t('table.soldoutFilter'),
      isFeatured: t('table.featureFilter')
    }
  }

  useEffect(() => {
    props.tableFuncs.current = onResetForm
    const sessionProductFilter = getStorage(localStorageKeys.PRODUCT_FILTER)
    if (isJsonString(sessionProductFilter)) {
      const productFilter = JSON.parse(sessionProductFilter)
      if (productFilter && productFilter.count > 0) {
        form.setFieldsValue(productFilter)
      }
    }
  }, [])

  const onApplyFilter = () => {
    const formValue = form.getFieldsValue()
    formValue.count = countFilterControl(formValue)
    const filterData = {
      productCategoryId: formValue.productCategoryId,
      productRootCategoryId: formValue.productRootCategoryId,
      count : formValue.count,
      isActive: formValue.isActive,
      filter: formValue.isActive === null || formValue.isActive === undefined  ? true : false,
      ...formValue
    }
    setStorage(localStorageKeys.PRODUCT_FILTER, JSON.stringify(formValue))
    props.fetchDataProducts(filterData, 1, pageSize, keySearch)
    setDataFilter(filterData)
    setResetFilter(!(formValue.count < 1))
  }

  const countFilterControl = (formValue) => {
    let countOther = 0
    const countCategory = formValue.productCategoryId === '' || formValue.productCategoryId === undefined ? 0 : 1
    const countStatus = formValue.isActive === null || formValue.isActive === undefined ? 0 : 1
    Object.keys(formValue).map((v,i)=>{
      if(v !== 'productCategoryId' || v !== 'productRootCategoryId' || v !== 'isActive'){
        if(formValue[v]){
          countOther++
        }
      }
    })
    return countCategory + countStatus + countOther
  }

  const onResetForm = () => {
    form?.resetFields()
    onApplyFilter()
    if (onShowFilter) {
      onShowFilter(false)
    }
  }

  return (
    <Form form={form} onFieldsChange={onApplyFilter} className="product-filter">
      <Card className="form-filter-popover">
        <Row gutter={[24,16]}>
          <Col span={12}>
            <div className='checkbox-filter-container'>
              <span className='first-column p-0 w-100'>{pageData.filter.isNewin}</span>
              <span className='pb-2'>
                <Form.Item className='m-0' name="isNewIn" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </span>
            </div>
            <div className='checkbox-filter-container'>
              <span className='first-column p-0 w-100'>{pageData.filter.isSoldout}</span>
              <span className='pb-2'>
                <Form.Item name="isSoldOut" valuePropName="checked" className='m-0'>
                  <Checkbox />
                </Form.Item>
              </span>
            </div>
          </Col>
          <Col span={12} className='second-column'>
            <div className='checkbox-filter-container'>
              <span className='first-column p-0 w-100'>{pageData.filter.isFeatured}</span>
              <span className='pb-2'>
                <Form.Item name="isFeatured" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </span>
            </div>
            <div className='checkbox-filter-container'>
              <span className='first-column p-0 w-100'>{pageData.filter.isDiscount}</span>
              <span className='pb-2'>
                <Form.Item name="isDiscounted" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="first-column">
            <span>{pageData.filter.productRootCategory.title}</span>
          </div>

          <div className="second-column">
            <Form.Item name="productRootCategoryId">
              <FnbSelectSingle
                placeholder={pageData.filter.category.placeholder}
                className="form-select"
                showSearch
                defaultValue={defaultValue}
                option={productRootCategories}
                onChange={onChangeProductRootCategory}
              />
            </Form.Item>
          </div>
        </Row>
        <Row>
          <div className="first-column">
            <span>{pageData.filter.category.title}</span>
          </div>

          <div className="second-column">
            <Form.Item name="productCategoryId">
              <FnbSelectSingle
                placeholder={pageData.filter.category.placeholder}
                className="form-select"
                showSearch
                defaultValue={defaultValue}
                option={categories}
              />
            </Form.Item>
          </div>
        </Row>
        <Row>
          <div className="first-column">
            <span>{pageData.filter.status.title}</span>
          </div>

          <div className="second-column">
            <Form.Item name="isActive">
              <Radio.Group defaultValue={null} buttonStyle="solid">
                <Radio.Button value={null}>
                  <CheckOutlined className="check-icon" /> {pageData.filter.status.all}
                </Radio.Button>
                <Radio.Button value={true}>
                  <CheckOutlined className="check-icon" /> {pageData.filter.status.active}
                </Radio.Button>
                <Radio.Button value={false}>
                  <CheckOutlined className="check-icon" /> {pageData.filter.status.inactive}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </Row>

        <Row className="row-reset-filter">
          <a
            onClick={() => onResetForm()}
            className="reset-filter"
            aria-current={!resetFilter && 'inventory-history-filter'}
          >
            {pageData.filter.resetAllFilters}
          </a>
        </Row>
      </Card>
    </Form>
  )
}
