import { Button, Image, message, Modal } from 'antd';
import FnbFroalaEditor from 'components/shop-froala-editor';
import { Reply, Star, StarFill, TrashFill, TrashFillSm } from 'constants/icons.constants';
import { images } from 'constants/images.constants';
import moment from 'moment';
import './email-detail.component.scss'
import { useState } from 'react';
import EmailDataService from 'data-services/contact/contact-data.service';
import { useTranslation } from 'react-i18next';
export default function EmailDetailModal(props){
  const { isModalOpen, email, onCancel, onChangeImportantStatus, onDeleteEmalConfirm } = props
  const [emailResponse, setResponse] = useState('')
  const [isResponse, setIsResponse] = useState(false)
  const {t} = useTranslation()
  const pageData = {
    updateEmailSuccess: t('email.updateEmailSuccess'),
    updateEmailFail: t('email.updateEmailFail'),
    emptyEmailWarning: t('email.emptyEmailWarning')
  }
  const onSendResponse = async() => {
    if(emailResponse === '' || !emailResponse){
      message.error(pageData.emptyEmailWarning)
      return
    }
    const request = {
      id: email?.id,
      message: emailResponse
    }
    try{
      const res = await EmailDataService.sendResponse(request)
      if(res){
        message.success(pageData.updateEmailSuccess)
        setResponse('')
        onCancel()
      }
    }catch(err){
      console.error(err)
      message.error(pageData.updateEmailFail)
    }
  }
  const renderSendButton = (
    <div className={`w-100 d-flex ${isResponse ? 'justify-start' :'justify-end'}`}>
      <Button onClick={() => setIsResponse(true)} className={`${isResponse && 'd-none'} mt-3 d-flex justify-content-around align-items-center`}>
        <Reply/>
        Trả Lời
      </Button>
      <Button onClick={()=>onSendResponse()} className={`${!isResponse && 'd-none'} mt-3 d-flex justify-content-around align-items-center`}>
        <Reply/>
          Gửi
      </Button>
    </div>
  )
  const renderResponse = (
    <div className='response-container'>
      <p>Cuc Hoa Mi</p>
      <div className='response'>
        <div className='mx-4' dangerouslySetInnerHTML={{__html: email?.answer}}/>
        <Reply/>
      </div>
    </div>

  )
  return(
    <Modal className='email-detail' open={isModalOpen} footer={null} onCancel={onCancel}>
      <div className='d-flex justify-content-between w-100 mt-4'>
        <Image src={images.thumbnailDefault} alt="thumbnail"/>
        <div className="d-flex info">
          <b>{ email?.userName }</b>
          <p className='ml-1'>{ email?.userEmail }</p>
        </div>
        <div>
          {moment(email?.createdTime).format('HH:mm DD/MM')}
        </div>
        <div className='action'>
          <span onClick={()=>onChangeImportantStatus(email?.id)}>
            { email?.isImportant ? <StarFill/> : <Star/> }
          </span>
          <Reply onClick={()=> !email?.isReply && setIsResponse(true)} className='ml-1'/>
          <TrashFillSm onClick={(e)=>onDeleteEmalConfirm(e, email)}/>
        </div>
      </div>
      <div className='my-3 message'>
        { email?.message }
      </div>
      <div className={`${!isResponse && 'd-none'}`}>
        <FnbFroalaEditor onChange={(e) => setResponse(e)}/>
      </div>
      {
        email?.isReply ? renderResponse : renderSendButton
      }
    </Modal>
  );
}