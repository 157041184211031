export const PermissionKeys = {
  ADMIN: '6C626154-5065-7265-6D69-737300000001',

  VIEW_PRODUCT: '6C626154-5065-7265-6D69-737300000002',
  CREATE_PRODUCT: '6C626154-5065-7265-6D69-737300000003',
  EDIT_PRODUCT: '6C626154-5065-7265-6D69-737300000004',

  VIEW_PRODUCT_CATEGORY: '6C626154-5065-7265-6D69-737300000005',
  CREATE_PRODUCT_CATEGORY: '6C626154-5065-7265-6D69-737300000006',
  EDIT_PRODUCT_CATEGORY: '6C626154-5065-7265-6D69-737300000007',

  VIEW_CUSTOMER: '6C626154-5065-7265-6D69-737300000008',
  CREATE_CUSTOMER: '6C626154-5065-7265-6D69-737300000009',
  EDIT_CUSTOMER: '6C626154-5065-7265-6D69-73730000000A',

  VIEW_STAFF: '6C626154-5065-7265-6D69-73730000000B',
  CREATE_STAFF: '6C626154-5065-7265-6D69-73730000000C',
  EDIT_STAFF: '6C626154-5065-7265-6D69-73730000000D',

  VIEW_ORDER: '6C626154-5065-7265-6D69-73730000000E',
  CREATE_ORDER: '6C626154-5065-7265-6D69-73730000000F',
  EDIT_ORDER: '6C626154-5065-7265-6D69-737300000010',

  VIEW_BLOG: '6C626154-5065-7265-6D69-737300000011',
  CREATE_BLOG: '6C626154-5065-7265-6D69-737300000012',
  EDIT_BLOG: '6C626154-5065-7265-6D69-737300000013',
}
