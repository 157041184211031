// import selfDelivery from "assets/icons/car-self-delivery.png";
// import completeGif from "assets/icons/complete.gif";
// import defaultImageBlog from "assets/icons/default-image-blog.svg";
import imgDefault from 'assets/images/img_default.png'
import productDefault from 'assets/images/product-default-img.png'
// import comingSoon from "assets/images/coming-soon.png";
// import smsLogoNoColor from "assets/images/sms-no-color.png";
// import smsLogo from "assets/images/sms.png";
import thumbnailDefault from 'assets/images/thumbnail-default.png'

export const images = {
  // selfDelivery: selfDelivery,
  // completeGif: completeGif,
  // defaultImageBlog: defaultImageBlog,
  imgDefault,
  productDefault,
  // comingSoon: comingSoon,
  // smsLogoNoColor: smsLogoNoColor,
  // smsLogo: smsLogo,
  thumbnailDefault
}
