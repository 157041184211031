import { CameraFilled } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, message, Row } from 'antd'
// import staffDataService from "data-services/staff/staff-data.service";
// import userDataService from "data-services/user/user-data.service";
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading'
import { useDispatch } from 'react-redux'
import { setFullNameUser, setThumbnailUser } from 'store/modules/session/session.actions'
import { fileNameNormalize, jsonToFormData } from 'utils/helpers'
import { UpdatePasswordComponent } from './update-password.component'
import { useTranslation } from 'react-i18next'
import staffDataService from 'data-services/staff/staff-data.service'
import { CalendarNewIconBold } from 'constants/icons.constants'
import { DateFormat } from 'constants/string.constants'
import ShopPermissionInput from 'components/shop-permission-input/shop-permission-input'
import { FnbImageSelectComponent } from 'components/shop-image-select/shop-image-select.component'

export default function UserInformationComponent (props) {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [avatarUrl, setAvatarUrl] = useState(null)
  const [isHiddenButtonUpdate, setIsHiddenButtonUpdate] = useState(true)
  const [fullNameCopy, setFullNameCopy] = useState(null)
  const [phoneNumberCopy, setPhoneNumberCopy] = useState(null)
  const [permissionIds, setPermissionIds] = useState([])
  const [isFormChange, setIsFormChange] = useState(false)
  const { t } = useTranslation()
  const pageData = {
    btnUpdate: t('account.btnUpdate'),
    loginNameLabel: t('account.loginNameLabel'),
    fullNameLabel: t('account.fullNameLabel'),
    phoneNumberLabel: t('account.phoneNumberLabel'),
    fullNamePlaceholder: t('account.fullNamePlaceholder'),
    phoneNumberPlaceholder: t('account.phoneNumberPlaceholder'),
    fullNameErrorText: t('account.fullNameErrorText'),
    phoneNumberErrorText: t('account.phoneNumberErrorText'),
    phoneNumberInvalidMessage: t('account.phoneNumberInvalidMessage'),
    maxLengthPhoneNumber: 15,
    formatPhoneNumber: '^[0-9]*$',
    successMessage: t('account.successMessage'),
    unSuccessMessage: t('account.unSuccessMessage'),
    tabNameAccountTitle: t('account.tabNameAccountTitle'),
    imageSizeTooBig: t('file.imageSizeTooBig'),
    birthdayLabel: t('account.birthdayLabel'),
    birthdayPlaceholder: t('account.birthdayPlaceholder')
  }

  useEffect(() => {
    getUserInformation()
  }, [])
  useEffect(() => {
    if(isFormChange){
      setIsFormChange(false)
      setIsHiddenButtonUpdate(false)
    }
  }, [isFormChange])
  const getUserInformation = () => {
    staffDataService
      .getCurrentStaffAsync()
      .then((response) => {
        form.setFieldsValue({
          ...response,
          birthday: moment(response.birthday)
        });
        setPermissionIds(response?.permissionIds)
        setFullNameCopy(response?.fullName);
        setPhoneNumberCopy(response?.phoneNumber);
        setTimeout(() => {
          setAvatarUrl(response?.thumbnail);
          form.setFieldValue('thumbnail', response?.thumbnail)
        }, 300);
      })
      .catch((errors) => {
        message.error(errors?.message);
      });
  }

  /**
   * Submit form value to server
   */
  const onFinish = () => {
    form.validateFields().then((values) => {
      values.permissionIds = permissionIds
      staffDataService
        .updateStaffProfile(values)
        .then((response) => {
          if (response) {
            setIsHiddenButtonUpdate(true);
            message.success(pageData.successMessage);
            dispatch(setFullNameUser(form.getFieldValue('fullName')))
            dispatch(setThumbnailUser(form.getFieldValue('thumbnail')))
          }
        })
        .catch((errors) => {
          console.log('Update current user errors >>', errors);
          message.error(pageData.unSuccessMessage)
        });
    });
  }
  const onChangeFullName = (e) => {
    const fullName = e?.target?.value
    const phoneNumber = form.getFieldValue('phoneNumber')
    if (fullName !== fullNameCopy || phoneNumber !== phoneNumberCopy) {
      setIsHiddenButtonUpdate(false)
    }
    if (fullName === fullNameCopy && phoneNumber === phoneNumberCopy) {
      setIsHiddenButtonUpdate(true)
    }
  }

  const onChangePhoneNumber = (e) => {
    const fullName = form.getFieldValue('fullName')
    const phoneNumber = e?.target?.value
    if (fullName !== fullNameCopy || phoneNumber !== phoneNumberCopy) {
      setIsHiddenButtonUpdate(false)
    }
    if (fullName === fullNameCopy && phoneNumber === phoneNumberCopy) {
      setIsHiddenButtonUpdate(true)
    }
  }
  if(!avatarUrl) return <p>Loading ...</p>
  return (
    <>
      <Row>
        <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
          <span className="title-account">{pageData.tabNameAccountTitle}</span>
        </Col>
        <Col span={12}>
          <Button
            className={'btn-update-account'}
            onClick={() => onFinish()}
            htmlType="button"
            hidden={isHiddenButtonUpdate}
          >
            {pageData.btnUpdate}
          </Button>
        </Col>
      </Row>
      <Form form={form} layout="vertical" autoComplete="off">
        <Row gutter={[16, 0]}>
          <Col span={12} className='mb-2'>
            <div className="group-user-avatar">
              <div className="upload__image-wrapper">
                <Form.Item name={'thumbnail'} rules={[{
                  required: true,
                  message: pageData.imageSizeTooBig
                }]}>
                  <FnbImageSelectComponent
                    onChange = {() => setIsFormChange(true)}
                    customTextNonImageClass={'create-edit-product-text-non-image'}
                    customNonImageClass={'create-edit-product-non-image'}
                    value={avatarUrl}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item name={'accountId'} hidden={true}></Form.Item>
            <Form.Item name={'staffId'} hidden={true}></Form.Item>
            <h4 className="shop-form-label">
              {pageData.fullNameLabel}
              <span className="text-danger">*</span>
            </h4>
            <Form.Item name={'fullName'} rules={[{ required: true, message: pageData.fullNameErrorText }]}>
              <Input
                className="shop-input"
                placeholder={pageData.fullNamePlaceholder}
                onChange={onChangeFullName}
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row>
              {/* <Col span={24}>
                <h4 className="shop-form-label">{pageData.storeNameLabel}</h4>
                <Form.Item name={"storeName"}>
                  <Input className="shop-input" disabled={true} />
                </Form.Item>
              </Col> */}
              <Col span={24}>
                <h4 className="shop-form-label">{pageData.loginNameLabel}</h4>
                <Form.Item name={'email'}>
                  <Input className="shop-input" disabled={true} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <h4 className="shop-form-label">
                  {pageData.phoneNumberLabel}
                  <span className="text-danger">*</span>
                </h4>
                <Form.Item
                  name={'phoneNumber'}
                  rules={[
                    {
                      required: true,
                      message: pageData.phoneNumberErrorText
                    },
                    {
                      pattern: new RegExp(pageData.formatPhoneNumber),
                      message: pageData.phoneNumberInvalidMessage
                    },
                    {
                      type: 'string',
                      max: pageData.maxLengthPhoneNumber
                    }
                  ]}
                >
                  <Input
                    className="w-100 shop-input-number"
                    placeholder={pageData.phoneNumberPlaceholder}
                    maxLength={pageData.maxLengthPhoneNumber}
                    onChange={onChangePhoneNumber}
                  />
                </Form.Item>
              </Col>
              <Col span={24} >
                <h4 className="shop-form-label ">{pageData.birthdayLabel}</h4>
                <Form.Item className='mt-1' name={'birthday'} >
                  <DatePicker
                    placeholder={pageData.birthdayPlaceholder}
                    suffixIcon={<CalendarNewIconBold />}
                    className="shop-date-picker w-100"
                    format={DateFormat.DD_MM_YYYY}/>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <ShopPermissionInput setIsFormChange={setIsFormChange} permissionIds={permissionIds} setPermissionIds={setPermissionIds}/>
        </Row>
      </Form>
      <UpdatePasswordComponent></UpdatePasswordComponent>
    </>
  )
}
